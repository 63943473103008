<template>
  <div class="grid grid-flow-row gap-6">
    <div
      v-for="block in content" :key="`${currentLocale}-${block.id}`"
      :class="BLOCKS_TO_STYLES_MAP[block.type]"
    >
      <component
        :is="BLOCKS_TO_COMPONENTS_MAP[block.type]"
        :content="getContent(block)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import TutorialText from '~/components/tutorialBlocks/TutorialText.vue'
import TutorialImages from '~/components/tutorialBlocks/TutorialImages.vue'
import TutorialVideo from '~/components/tutorialBlocks/TutorialVideo.vue'
import TutorialBulletPoints from '~/components/tutorialBlocks/TutorialBulletPoints.vue'
import TutorialNumberedList from '~/components/tutorialBlocks/TutorialNumberedList.vue'
import TutorialHint from '~/components/tutorialBlocks/TutorialHint.vue'
import useLocale from '~/composables/useLocale'
import { type OnboardingPageContent, OnboardingPageContentType } from '~/services/entities'

const TutorialSubheadline = defineAsyncComponent(() => import('~/components/tutorialBlocks/TutorialSubheadline.vue'))
const TutorialModelGroup = defineAsyncComponent(() => import('~/components/tutorialBlocks/TutorialModelGroup.vue'))
const TutorialContentTips = defineAsyncComponent(() => import('~/components/tutorialBlocks/TutorialContentTips.vue'))

const BLOCKS_TO_COMPONENTS_MAP = {
  'SUBHEADLINE': TutorialSubheadline,
  'TEXTBLOCK': TutorialText,
  'TEXTBLOCK_BULLET_POINT': TutorialText,
  'BULLET_POINTS': TutorialBulletPoints,
  'NUMBERED_LIST': TutorialNumberedList,
  'HINT': TutorialHint,
  'IMAGE': TutorialImages,
  'VIDEO': TutorialVideo,
  'MODEL_GROUP': TutorialModelGroup,
  'CONTENT_TIPS': TutorialContentTips,
}

const BLOCKS_TO_CONTENT_MAP = {
  'SUBHEADLINE': 'value',
  'TEXTBLOCK': 'value',
  'BULLET_POINTS': 'value',
  'NUMBERED_LIST': 'value',
  'TEXTBLOCK_BULLET_POINT': 'value',
  'HINT': 'value',
  'IMAGE': 'attachments',
  'VIDEO': 'attachments',
}

const BLOCKS_TO_STYLES_MAP = {
  'TEXTBLOCK_BULLET_POINT': '-mb-4',
}

defineProps({
  content: {
    type: Array<OnboardingPageContent>
  },
})

const getContent = (block: OnboardingPageContent) => {
  if ([
    OnboardingPageContentType.SUBHEADLINE,
    OnboardingPageContentType.MODEL_GROUP,
    OnboardingPageContentType.CONTENT_TIPS,
  ].includes(block.type)) {
    return block
  }

  return block[BLOCKS_TO_CONTENT_MAP[block.type]]
}

const { currentLocale } = useLocale()
</script>
