<template>
  <div
    class="tutorial-image-wrapper grid gap-6 grid-cols-2"
  >
    <div
      v-for="(image) in content"
      :key="image.attachment.id"
      class="tutorial-image relative"
      :class="hasOneImage ? 'single' : 'in-grid'"
      @click="viewImage(image)"
    >
      <img
        class="w-full h-auto"
        :src="image.attachment.url"
        :alt="image.attachment.original_filename"
      />
      <TutorialImageCaption
        v-if="isPDFContext && image.caption"
        :content="image.caption"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import useModal from '~/composables/useModal'
import { MediaType , type Media } from '~/models'
import TutorialImageCaption from '~/components/tutorialBlocks/TutorialImageCaption.vue'
import { PDFContextProviderKey } from '~/services/constants'

const { handleModal } = useModal()

const props = defineProps({
  content: {
    type: Array,
    required: true,
  }
})

const isPDFContext = inject(PDFContextProviderKey, false)

const { content } = toRefs(props)

const hasOneImage = computed(() => content.value.length === 1)
const images = computed(() => content.value.map((media: Media) => {
  media.attachment.media_type = MediaType.image
  return media
}))
const viewImage = async (img) => {
  await handleModal('modal-overlay-gallery',{
    selectedImage: img,
    collections: images
  })
}
</script>

<style scoped>
.tutorial-image {
  @apply flex flex-col cursor-pointer;
}
</style>
