<template>
  <div class="pl-8">
    <ul class="list-disc">
      <li
        v-for="(point, idx) in bulletPoints"
        :key="`point-${idx}`"
        class="mb-2"
        v-html="boldContent(point, boldBulletText)"
      />
    </ul>
  </div>
</template>

<script setup lang="ts">
import { boldContent } from '~/services/helpers'

const boldBulletText = /\*\*(?<boldText>.*?)\*\*/g

const props = defineProps({
  content: {
    type: String,
    required: true,
  }
})

const { content } = toRefs(props)

const bulletPoints = computed(() => {
  if (!content.value) {
    return []
  }
  const [firstPoint, ...rest] = content.value.split(/\n\*/).map(point => point.trim()).filter(Boolean)
  return [firstPoint.replace(/^\*\s/, ''), ...rest]
})
</script>
